<template>
    <div class="login-container">
        <el-form :model="ruleForm" :rules="rulesForm"
            status-icon
            ref="refForm" 
            label-position="left" 
            label-width="0px" 
            @submit.prevent="handleSubmit"
            class="login-rule-form login-page">
            <h3 class="title">账户登录</h3>
            <el-form-item prop="uname">
                <el-input type="text" 
                    v-model="ruleForm.username" 
                    auto-complete="off" 
                    placeholder="用户名"
                    size="large"
                ></el-input>
            </el-form-item>
            <el-form-item prop="pass">
                <el-input type="password" 
                    v-model="ruleForm.password" 
                    auto-complete="off" 
                    placeholder="密码"
                    size="large"
                ></el-input>
            </el-form-item>
            <el-form-item style="width:100%;">
                <el-button type="primary" size="large" native-type="submit" @click="handleSubmit" :loading="logining">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import cookies from 'vue-cookies'
import elplus from '@/utils/elplus'

export default {
    data(){
        return {
            // admin
            // 123456
            logining: false,
            ruleForm: {
                username: '',
                password: '',
            },
            rulesForm: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}]
            },
            checked: false
        }
    },
    methods: {
        handleSubmit(){
            this.$refs.refForm.validate((valid) => {
                if(valid){
                    let loading = elplus.loading()
                    this.$store.dispatch('AdminLogin', {...this.ruleForm}).then(res => {
                        loading.close()
                        if (res.ret) return this.$message.error(res.msg);
                        cookies.set("ADMIN_TOKEN", res.data.identifier, 60 * 60 * 24 * 7);
                        localStorage.setItem('ADMIN_USERINFO', JSON.stringify(res.data))
                        this.$store.commit('SET_USER_INFO', res.data)
                        this.$router.push('/')
                        this.logining = true;
                    }).catch((err) => {
                        loading.close()
                        console.log(err)
                        this.$message.error('网络错误，请检查网络');
                    })
                }else{
                    console.log('error submit!');
                    return false;
                }
            })
            return false
        }
    }
};
</script>


<style lang="scss" scoped>
.login-container {
    background: url(../assets/background.5825f033.svg) no-repeat 50%;
    background-size: 100%;
    padding: 210px 0 144px;
    position: relative;
    width: 100%;
    min-height: 100vh;
    .login-rule-form{
        width: 400px;
        margin: 0 auto;
        .title{
            text-align: center;
            margin-bottom: 30px;
            color: rgba(0,0,0,.85);
            font-weight: 600;
            font-size: 36px;
        }
        .el-button {
            width: 100%;
        }
    }
}
</style>
